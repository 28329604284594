import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
         <path d="M4960 10520 c-299 -29 -584 -84 -860 -166 -180 -54 -375 -122 -460
-161 -30 -14 -71 -31 -90 -38 -62 -22 -362 -172 -470 -235 -498 -288 -878
-611 -1294 -1100 -11 -14 -41 -52 -66 -85 -24 -33 -52 -67 -62 -75 -22 -19
-103 -137 -206 -300 -286 -452 -503 -983 -612 -1500 -67 -314 -90 -531 -97
-890 -7 -369 9 -584 66 -910 112 -644 376 -1283 759 -1840 238 -346 585 -714
937 -995 465 -371 1097 -693 1648 -840 31 -8 83 -22 115 -31 82 -22 382 -80
482 -93 176 -23 333 -33 580 -38 210 -4 294 -1 478 17 200 19 430 52 552 80
444 103 737 205 1100 385 224 111 491 268 620 365 19 14 80 60 135 101 114 85
110 81 270 222 123 108 361 346 452 452 96 113 203 250 203 262 0 6 3 13 8 15
19 8 224 325 287 443 26 50 52 95 56 100 16 22 99 190 99 203 0 6 5 12 10 12
6 0 10 5 10 11 0 12 20 61 51 122 10 22 19 43 19 48 0 5 11 31 24 58 13 27 29
70 37 95 7 25 17 46 21 46 4 0 8 10 8 23 0 13 4 27 8 33 5 5 17 36 26 69 34
115 39 130 47 150 14 31 51 176 56 218 3 20 9 37 14 37 5 0 9 12 9 27 0 15 4
40 9 57 12 38 37 177 62 336 36 236 52 607 38 885 -17 343 -20 385 -29 385 -5
0 -11 28 -13 63 -6 69 -18 141 -42 262 -10 44 -19 99 -22 123 -3 23 -9 42 -14
42 -5 0 -9 15 -9 34 0 19 -4 38 -10 41 -5 3 -10 19 -10 34 0 15 -3 31 -7 35
-5 4 -13 29 -20 54 -32 129 -76 266 -85 270 -4 2 -8 14 -8 26 0 12 -4 26 -8
32 -4 5 -14 27 -21 49 -7 22 -21 58 -31 80 -10 22 -26 58 -35 80 -10 22 -33
73 -51 112 -19 40 -34 79 -34 87 0 8 -4 16 -9 18 -5 2 -26 39 -48 83 -81 163
-141 267 -273 466 -82 122 -220 311 -240 329 -3 3 -38 44 -78 92 -75 91 -204
231 -282 307 -102 99 -198 189 -249 231 -30 25 -63 54 -75 65 -12 11 -37 31
-56 45 -19 13 -44 32 -54 42 -18 16 -150 110 -256 182 -25 16 -46 34 -48 38
-2 4 -8 8 -13 8 -6 0 -36 16 -67 37 -59 37 -67 42 -217 126 -110 62 -161 87
-175 87 -5 0 -10 5 -10 10 0 6 -6 10 -13 10 -8 0 -46 16 -86 35 -39 19 -78 35
-86 35 -8 0 -15 5 -15 10 0 6 -7 10 -15 10 -12 0 -84 30 -147 61 -10 5 -26 9
-36 9 -10 0 -22 4 -28 9 -8 8 -85 35 -289 101 -33 10 -98 29 -145 40 -47 12
-101 26 -120 31 -50 13 -263 56 -343 69 -316 50 -810 63 -1157 30z m880 -449
c41 -5 104 -13 140 -17 81 -8 333 -57 464 -90 443 -112 925 -330 1326 -599
115 -77 300 -216 346 -260 6 -5 40 -35 75 -65 200 -173 480 -483 633 -700 52
-74 216 -328 216 -335 0 -2 13 -27 29 -54 45 -75 144 -274 194 -386 77 -173
218 -587 205 -600 -2 -2 -28 8 -58 23 l-55 27 -1595 -3 c-1775 -3 -1656 2
-1724 -70 -30 -32 -31 -36 -36 -145 l-5 -112 -233 -5 c-138 -3 -236 -9 -240
-15 -4 -6 -20 -17 -35 -25 -43 -22 -86 -76 -99 -126 -14 -52 -23 -56 -32 -12
-12 59 -52 112 -108 144 l-53 29 -260 6 c-143 3 -261 6 -262 7 -2 1 -3 9 -3
17 0 8 -4 15 -8 15 -4 0 -46 50 -92 111 -47 61 -90 117 -96 123 -17 18 -178
238 -210 286 -16 25 -39 56 -51 70 -13 14 -23 30 -23 37 0 6 -3 13 -8 15 -8 3
-182 281 -182 291 0 3 -13 25 -29 49 -28 43 -73 125 -146 268 -21 41 -43 81
-49 89 -6 7 -11 19 -11 25 0 18 -68 155 -74 152 -3 -2 -7 14 -8 37 -2 22 -6
43 -10 46 -12 9 -39 71 -53 121 -7 25 -23 77 -37 115 -13 39 -26 79 -28 90
-19 88 -34 175 -34 202 -1 17 -6 33 -12 35 -10 3 -17 51 -19 143 -1 28 -4 43
-7 35 -3 -8 -9 -17 -13 -20 -12 -9 -30 -74 -22 -79 11 -9 32 -81 22 -81 -4 0
-11 10 -15 23 l-8 22 -4 -23 -4 -23 -449 3 c-246 2 -444 0 -438 -3 6 -4 6 -9
1 -12 -5 -3 -15 -2 -23 3 -12 7 -12 12 -2 22 17 17 15 33 -3 18 -9 -7 -18 -10
-21 -8 -8 8 25 38 42 38 8 0 13 4 9 9 -6 10 32 51 47 51 17 0 7 -21 -14 -30
-18 -7 -19 -9 -6 -9 28 -1 59 30 47 46 -12 13 9 26 64 37 25 6 40 36 17 36 -5
0 -10 -7 -10 -15 0 -9 -9 -15 -22 -15 -19 0 -14 8 32 50 54 48 86 67 59 34
-15 -18 -18 -18 48 1 12 3 24 1 28 -5 3 -5 13 -10 22 -10 13 0 13 3 3 15 -7 8
-10 22 -6 30 5 13 2 13 -19 -1 -18 -12 -29 -13 -42 -5 -10 6 -13 11 -5 11 7 0
27 15 45 33 18 17 50 41 70 52 20 11 37 23 37 27 0 4 6 5 14 2 8 -3 16 3 19
15 3 12 10 21 17 21 6 0 8 -5 4 -11 -4 -7 -1 -10 7 -7 8 3 14 13 14 23 0 10
15 26 35 38 19 10 75 44 124 75 48 32 94 56 102 54 8 -2 17 4 20 12 3 8 17 22
31 32 24 15 26 15 20 0 -4 -9 -2 -16 3 -16 6 0 10 6 10 14 0 24 81 70 125 70
22 0 32 1 22 4 -19 4 -13 22 8 22 8 0 27 12 42 26 51 48 287 150 545 235 345
113 716 180 1093 197 170 8 523 -2 635 -17z m-2364 -1108 c-10 -10 -19 5 -10
18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m-986 -77 c0 -3 -4 -8 -10 -11 -5 -3
-10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-30 -29 c0 -2 -9 -12 -20 -22
-24 -21 -27 -14 -4 9 15 15 24 20 24 13z m-50 -107 c0 -5 0 -13 0 -17 0 -4
-11 -8 -25 -10 -25 -4 -25 -3 -8 16 20 22 33 26 33 11z m-10 -46 c0 -8 -5 -12
-10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z m-72 -36 c-3 -29 -7 -33 -36 -36
-31 -3 -33 -2 -27 20 11 33 25 48 47 48 16 0 19 -6 16 -32z m1199 -30 c-3 -7
-5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-1141 -10 c-20 -11 -21 -32 -1
-24 26 9 17 -20 -10 -32 -20 -10 -23 -9 -18 5 4 11 2 14 -8 11 -8 -3 -13 -10
-12 -15 1 -5 -5 -10 -13 -10 -23 0 -17 13 28 57 25 24 44 36 47 29 2 -6 -4
-16 -13 -21z m-156 -32 c0 -8 -4 -17 -9 -21 -12 -7 -24 12 -16 25 9 15 25 12
25 -4z m1330 -26 c0 -5 -10 -10 -22 -10 -19 0 -20 2 -8 10 19 13 30 13 30 0z
m-1400 -44 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z
m-20 -52 c0 -2 -7 -4 -15 -4 -8 0 -15 4 -15 10 0 5 7 7 15 4 8 -4 15 -8 15
-10z m11 -37 c-5 -5 -24 -4 -42 1 l-34 10 42 -1 c23 -1 38 -6 34 -10z m10 -56
c35 -22 70 -46 77 -52 8 -6 47 -28 88 -49 40 -20 72 -39 69 -41 -8 -8 67 -53
86 -52 10 1 23 -7 29 -17 5 -10 27 -28 47 -41 55 -33 332 -217 370 -247 18
-13 33 -22 33 -19 0 6 201 -139 233 -167 9 -9 19 -16 22 -16 9 0 140 -92 143
-101 2 -5 9 -9 15 -9 7 0 24 -11 39 -25 15 -14 30 -25 34 -25 3 0 18 -10 33
-22 15 -13 40 -32 55 -43 29 -21 93 -69 111 -83 6 -4 31 -23 58 -41 26 -19 47
-38 47 -43 0 -4 5 -8 11 -8 9 0 106 -70 149 -108 8 -8 43 -35 77 -62 l63 -49
-388 5 c-213 3 -409 10 -437 15 -77 15 -843 31 -935 20 -449 -57 -859 -427
-1005 -906 -49 -161 -65 -274 -65 -450 0 -243 41 -437 132 -625 159 -329 412
-540 776 -646 163 -48 277 -56 797 -58 532 -1 538 -1 743 67 l100 34 7 -71
c10 -107 57 -179 141 -217 39 -18 99 -19 1429 -19 1316 0 1388 -1 1401 -17 7
-10 25 -42 40 -71 15 -29 31 -55 36 -58 6 -4 68 -7 139 -8 127 -1 129 -1 156
-29 26 -26 28 -32 27 -112 l-1 -85 48 0 c26 0 51 -4 54 -10 14 -22 23 -7 29
47 4 34 3 60 -4 67 -5 5 -10 22 -10 37 0 15 -6 29 -12 32 -39 13 140 63 190
53 15 -3 60 -2 101 2 l73 7 -48 35 c-27 19 -56 40 -65 45 -22 13 -106 71 -122
85 -6 6 -58 44 -114 86 -57 42 -103 80 -103 85 0 5 274 9 665 9 734 0 698 -3
773 64 43 39 29 10 287 621 54 127 110 259 125 295 15 36 51 121 80 190 75
181 102 243 149 351 22 53 41 101 41 108 0 20 39 51 65 51 35 0 94 32 132 72
53 55 63 99 64 287 0 173 9 187 28 46 5 -44 16 -118 23 -165 18 -120 18 -773
0 -895 -29 -190 -64 -401 -72 -435 -5 -19 -19 -75 -31 -125 -24 -101 -33 -133
-83 -290 -110 -346 -275 -694 -470 -990 -98 -150 -184 -265 -197 -265 -6 0
-25 11 -43 25 -18 14 -38 25 -44 25 -7 0 -12 5 -12 10 0 6 -4 10 -9 10 -9 0
-243 143 -308 188 -110 76 -296 192 -300 188 -3 -3 -7 -29 -9 -58 -16 -207
-164 -300 -367 -230 -55 19 -67 44 -44 88 l15 29 -182 3 c-208 4 -196 4 -259
-4 -34 -4 -54 -2 -71 10 -21 13 -26 13 -36 1 -10 -12 -8 -26 13 -73 42 -95
104 -270 135 -387 67 -246 86 -394 79 -600 -5 -165 -17 -252 -48 -365 -30
-106 -52 -124 -235 -195 -82 -31 -158 -61 -169 -66 -124 -53 -523 -160 -740
-198 -266 -46 -387 -56 -700 -56 -595 1 -1011 84 -1645 330 -83 32 -377 174
-405 195 -5 4 -30 19 -55 32 -129 72 -461 296 -500 338 -3 3 -21 17 -42 32
-20 15 -42 33 -50 40 -7 7 -52 47 -98 88 -325 288 -624 654 -855 1045 -47 79
-205 388 -205 400 0 3 -11 31 -25 61 -43 94 -145 388 -181 521 -113 418 -161
799 -151 1213 19 824 255 1571 710 2255 143 214 137 207 168 201 15 -3 55 -23
90 -45z m59 9 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m103 -85 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z
m304 -171 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m233 -164 c0
-5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m937
-12 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-697 -148 c0 -5 -2
-10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m67 -961 c131
-17 224 -57 307 -130 20 -17 41 -29 48 -27 6 2 19 31 28 64 l17 59 259 0 259
0 0 -65 0 -65 -76 -5 c-86 -7 -138 -33 -182 -92 -49 -67 -52 -87 -52 -348 0
-135 4 -264 8 -287 l9 -43 -141 0 c-161 0 -152 -5 -166 96 -18 118 -77 214
-156 255 -47 24 -151 26 -203 4 -68 -29 -135 -112 -176 -220 -33 -86 -54 -254
-45 -365 17 -211 91 -348 227 -420 59 -32 69 -34 165 -34 69 -1 106 3 114 12
7 6 20 12 29 12 31 0 157 95 241 182 l87 88 63 -71 c35 -39 69 -80 76 -92 8
-14 12 -68 13 -144 l0 -122 -27 -15 c-79 -42 -115 -103 -121 -206 l-4 -76 -52
-23 c-70 -32 -176 -71 -206 -77 -14 -2 -32 -7 -40 -10 -26 -10 -212 -33 -267
-34 -88 0 -335 42 -358 62 -5 4 -17 8 -27 8 -23 0 -178 70 -178 80 0 4 -10 10
-23 14 -27 6 -159 115 -208 171 -44 51 -90 113 -108 145 -36 66 -81 161 -81
171 0 6 -6 25 -14 42 -8 18 -17 46 -19 62 -3 17 -12 66 -20 110 -52 275 -9
563 116 795 174 322 473 531 784 549 12 0 57 -4 100 -10z m1183 -40 c0 -5 4
-8 9 -5 5 4 11 3 13 -1 1 -5 31 -31 66 -60 58 -48 61 -53 40 -58 -13 -3 -51
-5 -83 -3 l-60 3 -3 68 c-2 42 1 67 8 67 5 0 10 -5 10 -11z m2998 -37 c9 -7
12 -59 10 -228 l-3 -219 -64 -3 -63 -3 5 -33 c3 -19 15 -53 26 -77 12 -23 21
-50 21 -59 0 -9 4 -20 8 -26 5 -5 17 -31 26 -59 10 -27 21 -59 26 -70 26 -61
80 -212 80 -222 0 -7 5 -13 10 -13 6 0 10 -8 10 -17 0 -9 12 -55 26 -102 15
-47 28 -92 30 -98 2 -7 10 -13 17 -13 9 0 23 28 38 73 12 39 26 79 30 87 4 8
29 69 55 135 26 66 51 124 56 130 4 5 8 15 8 22 0 7 12 38 26 70 42 93 54 121
54 127 0 3 9 25 20 49 11 23 21 53 22 67 3 24 0 25 -57 30 l-60 5 0 225 0 225
450 0 450 0 3 -227 2 -227 -77 -3 -78 -3 -22 -45 c-30 -63 -53 -117 -53 -124
0 -3 -11 -29 -24 -58 -13 -29 -43 -98 -66 -153 -23 -55 -50 -118 -60 -140 -10
-22 -61 -143 -115 -270 -54 -126 -105 -248 -115 -270 -48 -109 -80 -188 -80
-201 0 -8 -4 -14 -10 -14 -5 0 -10 -6 -10 -12 0 -7 -11 -35 -25 -63 -13 -27
-25 -58 -25 -67 0 -10 -4 -18 -10 -18 -5 0 -10 -7 -10 -16 0 -8 -12 -41 -26
-72 -15 -31 -31 -68 -36 -82 -24 -59 -2 -55 -312 -55 l-287 0 -29 55 c-32 62
-150 309 -150 315 0 2 -37 80 -82 172 l-83 168 -3 211 -3 212 46 33 c26 19 59
54 73 79 l27 45 0 290 0 290 -33 47 c-18 26 -54 60 -80 75 l-47 28 -285 5
-285 5 -3 33 c-7 66 -46 62 555 62 301 0 554 -4 561 -8z m-2225 -329 c6 -9 42
-138 58 -208 17 -70 27 -107 61 -235 19 -69 33 -130 31 -137 -4 -12 -53 6 -53
20 0 4 -7 7 -16 7 -9 0 -34 15 -54 33 -53 45 -94 72 -103 69 -5 -1 -6 2 -2 7
3 6 -4 18 -16 28 -12 10 -53 49 -91 86 -37 38 -68 64 -68 58 0 -18 -25 -12
-40 9 -8 11 -18 20 -23 20 -5 0 -7 -9 -4 -20 5 -20 0 -20 -238 -20 l-244 0 -3
-27 -3 -28 -27 28 c-24 26 -26 26 -21 6 4 -15 3 -20 -5 -15 -19 12 -14 1 10
-20 12 -10 18 -13 14 -6 -6 10 -3 13 10 10 19 -3 33 -48 16 -48 -18 0 11 -58
34 -71 12 -6 25 -19 28 -28 3 -9 14 -29 25 -46 21 -32 27 -90 11 -100 -5 -3
-10 -11 -10 -16 0 -13 96 -109 110 -109 6 0 9 -4 6 -8 -3 -5 24 -37 59 -72 36
-36 65 -71 65 -80 0 -13 2 -13 11 0 9 13 10 13 5 -2 -6 -19 14 -640 21 -648 3
-3 37 -7 76 -10 l72 -5 0 -205 0 -205 -400 0 -400 0 -3 207 -2 207 72 3 73 3
2 560 c2 308 -1 566 -6 574 -5 8 -26 13 -52 13 -97 -1 -89 -19 -89 213 l0 204
28 3 c67 8 1071 8 1075 1z m1595 -205 c2 -162 0 -208 -10 -209 -30 -4 -55 -6
-89 -7 l-36 -2 -8 -562 c-8 -642 -16 -588 87 -588 l58 0 0 -204 c0 -223 2
-216 -61 -216 -32 0 -60 25 -351 314 -197 196 -214 221 -205 296 l6 48 -81 80
-81 80 2 113 c1 61 -1 127 -5 146 -7 34 -24 46 -24 16 0 -10 -7 -27 -15 -37
-8 -11 -15 -28 -15 -39 0 -11 -8 -41 -17 -67 l-17 -47 -149 148 c-83 82 -154
149 -159 149 -4 0 -8 4 -8 10 0 5 -20 29 -45 53 -25 24 -45 53 -45 63 0 10 12
64 26 119 14 55 30 116 34 135 4 19 27 106 49 194 23 87 41 165 41 174 0 8 7
24 17 34 15 17 43 18 557 16 l541 -3 3 -207z m-1768 -155 c0 -2 -4 -3 -9 -3
-12 0 -61 50 -61 63 0 8 70 -52 70 -60z m40 -51 c0 -13 -3 -12 -15 4 -19 25
-19 40 0 24 8 -7 15 -20 15 -28z m65 -72 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11
10 0 6 2 10 4 10 3 0 8 -4 11 -10z m253 -215 c0 -5 -6 4 -13 20 -8 17 -14 37
-14 45 0 8 7 -1 14 -20 7 -19 13 -39 13 -45z m-577 -305 c12 0 69 -58 69 -70
0 -6 5 -10 11 -10 14 0 69 -55 69 -69 0 -6 4 -11 10 -11 13 0 70 -57 70 -70 0
-5 4 -10 9 -10 12 0 71 -58 71 -70 0 -4 8 -10 18 -14 9 -3 24 -10 32 -15 27
-17 83 -41 97 -41 7 0 13 -4 13 -10 0 -5 6 -10 14 -10 40 0 178 -107 324 -252
93 -93 122 -128 122 -148 -1 -40 -39 -173 -53 -182 -14 -9 -540 -11 -586 -2
-17 4 -31 13 -31 20 0 7 -6 33 -14 56 -7 24 -30 106 -51 183 -20 77 -41 149
-45 160 -5 11 -11 31 -13 45 -6 30 -46 167 -57 195 -5 11 -18 56 -30 100 -21
80 -53 197 -62 225 -3 8 -3 12 -1 8 3 -5 9 -8 14 -8z m1108 -962 c9 -15 8 -20
-4 -24 -27 -11 -45 4 -45 36 0 27 2 29 19 19 10 -7 23 -20 30 -31z m1358 -510
c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z"/>
<path d="M3260 9460 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3165 9400 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M3456 9381 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M3481 9344 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2938 9233 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2908 9203 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3440 9180 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"/>
<path d="M3363 9120 c0 -41 2 -58 4 -37 2 20 2 54 0 75 -2 20 -4 3 -4 -38z"/>
<path d="M3440 9095 c0 -14 5 -25 10 -25 6 0 10 11 10 25 0 14 -4 25 -10 25
-5 0 -10 -11 -10 -25z"/>
<path d="M2670 8994 c0 -14 4 -23 10 -19 6 3 10 15 10 26 0 10 -4 19 -10 19
-5 0 -10 -12 -10 -26z"/>
<path d="M2580 8921 c0 -16 28 -20 40 -5 10 12 8 14 -14 14 -14 0 -26 -4 -26
-9z"/>
<path d="M2290 8665 c-10 -12 -10 -15 4 -15 9 0 16 7 16 15 0 8 -2 15 -4 15
-2 0 -9 -7 -16 -15z"/>
<path d="M4390 4085 c-49 -10 -55 -14 -64 -43 -7 -26 -14 -32 -35 -32 -15 0
-32 -5 -37 -10 -7 -7 -29 -6 -69 4 -96 25 -171 -3 -208 -77 -27 -54 -16 -91
39 -130 24 -17 44 -34 44 -39 0 -13 -45 -9 -66 7 -10 8 -22 15 -26 15 -9 0
-68 -82 -68 -96 0 -16 84 -44 134 -44 87 0 166 66 166 138 0 46 -14 68 -63 99
-47 29 -37 41 19 23 30 -10 38 -10 45 1 4 8 15 23 24 34 15 20 16 20 13 -5 -2
-20 2 -26 20 -28 15 -2 22 -9 18 -17 -2 -7 -12 -48 -21 -91 -22 -101 -13 -128
45 -145 34 -10 52 -10 98 2 l57 14 0 45 c0 44 0 45 -32 43 -17 -2 -34 1 -37 5
-3 5 2 38 12 73 16 60 19 64 49 67 26 3 34 10 51 50 11 26 18 50 16 54 -3 5
-20 8 -39 8 l-33 0 10 36 c5 20 6 39 1 43 -4 4 -33 2 -63 -4z"/>
<path d="M5692 4087 c-46 -10 -53 -14 -64 -44 -7 -20 -19 -33 -29 -33 -29 0
-49 -11 -49 -27 0 -11 -6 -10 -26 6 -64 51 -174 31 -243 -44 -48 -52 -61 -80
-71 -150 -18 -129 57 -180 208 -144 64 16 72 24 72 76 0 43 -1 45 -25 39 -14
-4 -45 -9 -70 -13 -55 -7 -62 12 -7 20 52 9 127 59 143 96 17 38 55 45 45 9
-26 -93 -38 -158 -32 -181 13 -53 102 -71 188 -38 25 9 27 15 24 53 l-2 43
-39 -3 c-35 -3 -37 -2 -31 20 4 13 12 47 18 76 12 50 14 52 45 52 29 0 34 5
52 46 24 54 21 64 -21 64 -30 0 -30 0 -24 45 4 25 4 44 -1 43 -4 0 -32 -6 -61
-11z m-292 -202 c-7 -8 -19 -15 -28 -15 -12 0 -10 6 9 26 24 26 41 16 19 -11z"/>
<path d="M4520 4070 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M3275 4030 c3 -5 12 -7 20 -3 21 7 19 13 -6 13 -11 0 -18 -4 -14 -10z"/>
<path d="M3568 4033 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3955 4030 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M7708 4030 c-15 -4 -30 -5 -34 -1 -4 3 -1 -4 5 -16 6 -13 16 -23 22
-23 6 0 7 5 4 10 -4 6 9 17 27 25 35 15 21 19 -24 5z"/>
<path d="M3125 4011 c-80 -20 -131 -69 -166 -161 -58 -153 34 -245 196 -196
35 10 40 15 39 41 -3 68 -1 65 -51 62 l-48 -2 -3 35 c-3 42 22 108 45 117 8 3
24 1 35 -6 22 -13 42 -5 77 33 21 22 22 28 10 42 -15 19 -71 45 -91 43 -7 -1
-26 -4 -43 -8z"/>
<path d="M3384 4010 c-73 -17 -144 -101 -165 -195 -24 -108 26 -175 131 -175
125 1 231 133 217 273 -5 56 -24 80 -76 95 -42 13 -61 14 -107 2z m44 -129 c3
-19 -25 -121 -33 -121 -2 0 -10 -3 -19 -6 -31 -12 -13 116 20 149 13 12 29 2
32 -22z"/>
<path d="M6117 4006 c-93 -26 -167 -132 -167 -241 0 -46 5 -60 28 -86 33 -37
94 -48 171 -29 l51 12 0 50 0 49 -42 -6 c-59 -8 -72 3 -63 52 13 78 60 124 92
92 8 -8 22 -3 52 22 45 36 47 44 10 71 -34 25 -76 30 -132 14z"/>
<path d="M6395 4011 c-45 -11 -68 -26 -108 -70 -60 -66 -86 -193 -51 -247 49
-74 181 -72 259 4 47 47 68 97 73 175 5 79 -10 111 -63 133 -37 15 -63 16
-110 5z m41 -115 c7 -19 -24 -122 -41 -136 -23 -19 -37 -1 -31 39 14 82 56
138 72 97z"/>
<path d="M3644 3998 l-42 -10 5 -62 c5 -57 -22 -197 -48 -247 -14 -27 11 -36
75 -30 l59 6 27 106 c14 59 32 114 39 123 18 22 44 21 37 -1 -19 -63 -55 -227
-51 -231 5 -5 125 -6 130 0 8 8 58 245 59 280 1 54 -25 78 -86 78 -27 0 -64
-6 -83 -14 -29 -12 -34 -12 -39 0 -6 16 -22 16 -82 2z"/>
<path d="M4541 3988 c-22 -60 -63 -260 -57 -283 3 -13 16 -34 28 -45 12 -11
17 -20 11 -20 -9 0 -63 -83 -63 -97 0 -10 80 -36 127 -40 40 -4 63 0 95 15 84
39 114 94 128 235 5 51 18 126 29 167 12 41 21 78 21 83 0 4 -29 7 -64 7 l-65
0 -15 -52 c-8 -29 -20 -73 -26 -98 -13 -50 -46 -110 -61 -110 -11 0 -5 42 21
145 10 39 20 80 23 93 5 21 2 22 -59 22 -58 0 -65 -2 -73 -22z m118 -344 c-23
-23 -31 -25 -73 -19 -25 4 -46 11 -46 15 0 4 10 5 23 3 12 -2 40 2 62 10 58
21 63 20 34 -9z"/>
<path d="M4917 3998 c-39 -10 -39 -11 -33 -52 7 -48 1 -81 -48 -268 -20 -76
-36 -149 -36 -162 0 -22 2 -23 37 -16 20 5 39 8 42 8 3 -1 18 2 33 6 19 6 28
16 32 37 3 16 9 43 12 59 4 22 11 30 26 30 71 0 163 61 199 132 23 45 35 131
25 172 -15 62 -83 84 -166 54 -26 -9 -35 -9 -38 0 -4 14 -32 14 -85 0z m137
-187 c-9 -25 -22 -49 -28 -53 -16 -11 -36 -10 -36 2 0 21 43 121 58 137 16 16
17 15 20 -12 2 -15 -4 -49 -14 -74z"/>
<path d="M6645 3996 c-22 -6 -39 -15 -38 -19 18 -62 16 -78 -30 -262 -9 -33
-14 -61 -12 -63 2 -2 31 -5 65 -6 71 -3 68 -7 94 113 19 85 43 141 61 141 9 0
4 -29 -16 -102 -16 -57 -32 -113 -35 -125 -3 -13 -2 -23 2 -23 5 -1 36 -2 69
-3 l60 -2 26 100 c30 113 46 152 65 159 18 7 19 9 -20 -129 -18 -66 -30 -122
-25 -125 4 -3 34 -4 67 -2 68 4 60 -8 101 160 26 104 27 163 3 185 -22 20 -98
23 -137 5 -19 -9 -28 -9 -37 0 -15 15 -95 15 -137 1 -24 -9 -35 -9 -44 0 -14
14 -30 13 -82 -3z"/>
<path d="M7349 3997 l-37 -10 5 -57 c4 -41 -2 -85 -21 -163 -14 -59 -26 -110
-26 -114 0 -5 31 -7 70 -5 57 3 70 7 70 20 0 9 10 56 22 104 21 83 58 151 74
136 4 -4 -7 -62 -25 -128 -17 -67 -30 -124 -27 -128 3 -4 33 -7 67 -6 l63 2
35 140 c45 183 38 212 -51 220 -35 3 -66 -1 -90 -11 -31 -13 -37 -13 -42 -1
-6 16 -34 16 -87 1z"/>
<path d="M7785 3992 c-142 -69 -195 -290 -81 -337 45 -19 70 -19 106 0 36 19
40 2 5 -21 -24 -16 -40 -16 -108 1 -22 6 -29 1 -51 -34 -14 -22 -26 -47 -26
-56 0 -21 102 -49 157 -41 116 16 169 81 183 223 4 48 18 126 29 172 13 53 18
88 12 94 -5 5 -49 11 -98 14 -72 3 -95 0 -128 -15z m82 -96 c-3 -8 -11 -32
-16 -54 -13 -47 -50 -97 -63 -84 -12 12 12 108 34 132 22 23 53 27 45 6z"/>
<path d="M5921 3884 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5911 3844 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3145 3829 c-9 -27 0 -35 20 -19 19 16 19 26 1 33 -9 4 -17 -2 -21
-14z"/>
<path d="M6160 3819 c0 -18 2 -19 15 -5 8 8 15 17 15 20 0 3 -7 6 -15 6 -9 0
-15 -9 -15 -21z"/>
<path d="M5801 3761 c-54 -50 -33 -121 35 -121 33 1 55 15 72 47 44 82 -38
139 -107 74z"/>
<path d="M7131 3764 c-28 -23 -32 -32 -29 -68 2 -34 7 -42 30 -50 37 -13 82 8
103 48 16 29 16 36 4 62 -20 41 -65 44 -108 8z"/>
<path d="M4184 3656 c-3 -8 -14 -18 -25 -21 -11 -4 -17 -10 -14 -15 7 -11 68
21 67 35 -1 16 -22 17 -28 1z"/>
<path d="M3470 3630 c-21 -11 -28 -19 -18 -20 25 0 68 18 68 30 0 14 -10 12
-50 -10z"/>
<path d="M6497 3644 c-11 -11 3 -24 25 -24 17 0 18 2 8 15 -13 16 -23 19 -33
9z"/>
<path d="M3195 3620 c-3 -5 3 -10 14 -10 11 0 23 5 26 10 3 6 -3 10 -14 10
-11 0 -23 -4 -26 -10z"/>
<path d="M4986 3573 c-3 -16 -8 -39 -12 -53 -6 -22 -5 -23 9 -10 18 18 34 90
19 90 -5 0 -12 -12 -16 -27z"/>
<path d="M4065 6420 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4640 6353 c0 -13 29 -38 35 -31 3 3 -4 13 -15 23 -11 10 -20 13 -20
8z"/>
<path d="M4710 6331 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M4724 6254 c2 -16 10 -30 18 -32 20 -4 18 25 -3 44 -17 15 -18 14
-15 -12z"/>
<path d="M4790 6245 c7 -8 17 -15 22 -15 6 0 5 7 -2 15 -7 8 -17 15 -22 15 -6
0 -5 -7 2 -15z"/>
<path d="M4151 6227 c-15 -19 -2 -29 15 -12 8 7 14 16 14 19 0 11 -17 6 -29
-7z"/>
<path d="M4835 6200 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4220 6170 c0 -5 -7 -10 -17 -10 -9 0 -14 -3 -10 -6 8 -8 47 5 47 17
0 5 -4 9 -10 9 -5 0 -10 -4 -10 -10z"/>
<path d="M4231 6124 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
